<template>
  <button type="button" onclick="window.location.reload()">Reload</button>
  <div>isWebViewBridgeAvailable: {{isWebViewBridgeAvailable()}}</div>
  <div><button @click="pwa">bridge.pwa</button></div>
</template>

<script>
import * as orta from "@orta/bridge";
export default {
  name: 'App',
  methods: {
    isWebViewBridgeAvailable: orta.isWebViewBridgeAvailable,
    pwa () {
      orta.pwa().then(resolve=>{
        alert('resolved' + resolve.toString())
      }).catch(e => {
        alert('error:' + JSON.stringify(e))
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
