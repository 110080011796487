/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      let msg =
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    registered () {
      let msg = 'Service worker has been registered.'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    cached () {
      let msg = 'Content has been cached for offline use.'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    updatefound () {
      let msg = 'New content is downloading.'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    updated () {
      let msg = 'New content is available; please refresh.'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    offline () {
      let msg = 'No internet connection found. App is running in offline mode.'
      console.log(msg)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    },
    error (error) {
      let msg = 'Error during service worker registration:'
      console.error(msg, error)
      document.getElementById('rhyme').innerHTML += ''+ new Date().toLocaleTimeString()+':' + msg + '<br>'
    }
  })
}
